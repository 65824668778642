import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import PageLoader from './components/Common/PageLoader'
import ServerError from './components/Common/500'
Vue.component('ServerError', ServerError)
import VueElementLoading from "vue-element-loading";
Vue.component("VueElementLoading", VueElementLoading);
Vue.component('PageLoader', PageLoader)
Vue.component('ServerError', ServerError)
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
Vue.component(VueCropper);
import 'viewerjs/dist/viewer.css'
import viewer from 'v-viewer'
Vue.use(viewer)
Vue.config.productionTip = false
window.axios = require('axios')
axios.defaults.baseURL = 'https://api.speleoindia.org.in';
Vue.prototype.baseURL = "https://api.speleoindia.org.in"
Vue.prototype.mediaURL = "https://api.speleoindia.org.in/wp/"
Vue.mixin({
    data() {
        return {
            webpSupported: true,
        };
    },

    created() {
        (async() => {
            // If browser doesn't have createImageBitmap, we can't use webp.
            if (!self.createImageBitmap) {
                this.webpSupported = false;
                return;
            }
            // Base64 representation of a white point image
            const webpData =
                "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
            // Retrieve the Image in Blob Format
            const blob = await fetch(webpData).then((r) => r.blob());
            // If the createImageBitmap method succeeds, return true, otherwise false
            this.webpSupported = await createImageBitmap(blob).then(
                () => true,
                () => false
            );
            if (this.webpSupported) {
                Vue.prototype.mediaURL = "https://api.speleoindia.org.in/wp/";
            } else {
                Vue.prototype.mediaURL = "https://api.speleoindia.org.in/u/";
            }
        })();
    },
});
new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')