<template>
  <div>
    <!-- <PageLoader /> -->
    <v-app>
      <router-view :key="$route.fullPath" v-bind:storage="sideNav"/>
    </v-app>
  </div>
</template>

<script>
// import axios from "axios";
import "./assets/style/font.css";
import "./assets/style/style.css";
export default {
  name: "App",
  data: () => ({
    //
     sideNav: false,
  }),
  // beforeMount() {
  //   if (typeof localStorage.getItem("token") == "string") {
  //     this.$store.commit("appLoading", true);
  //     axios({
  //       method: "GET",
  //       url: "/user/profile",
  //       headers: {
  //         "x-auth-token": localStorage.getItem("token"),
  //       },
  //     })
  //       .then((response) => {
  //         if (response.data.status) {
  //           this.$store.commit("appLoading", false);
  //           this.$store.commit("userData", response.data.data);
  //           this.$store.commit("userType", response.data.role);
  //           // this.$store.commit("menu", response.data.menu);
  //         }
  //       })
  //       .catch((err) => {
  //         this.$store.commit("appLoading", false);
  //         console.log(err);
  //       });
  //   }
  // },
};
</script>
