import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import axios from 'axios'
Vue.use(Router)
let router = new Router({
    mode: 'history',
    routes: [{
            path: "/appSidebar",
            props: true,
            component: () =>
                import ("./components/Common/appSidebar"),
            meta: {
                requireAuth: true,
            },
            children: [{
                    path: "/appDashboard",
                    name: "dashboard",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/Common/appDashboard"),
                }, {
                    path: "/home",
                    name: "home",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/Home/home"),
                }, {
                    path: "/gallery",
                    name: "gallery",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/Home/Gallery/gallery"),
                }, {
                    path: "/team",
                    name: "team",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/Team/team"),
                }, {
                    path: "/about",
                    name: "about",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/About/about"),
                }, {
                    path: "/getInvolved",
                    name: "getInvolved",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/GetInvolved/getInvolved"),
                },
                {
                    path: "/volunteer",
                    name: "volunteer",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/GetInvolvedList/volunteer"),
                },
                {
                    path: "/contact",
                    name: "contact",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/Contact/contact"),
                }, {
                    path: "/contactList",
                    name: "contactList",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/Contact/contactList"),
                }, {
                    path: "/contactDetails",
                    name: "contactDetails",
                    meta: {
                        requireAuth: true,
                    },
                    component: () =>
                        import ("./components/Contact/contactDetails"),
                },
            ]
        },
        {
            path: "/",
            name: "login",
            meta: {
                requireAuth: false,
            },
            component: () =>
                import ("./components/Login/login"),
        },
        {
            path: '*',
            name: '404PageNotFound',
            props: true,
            meta: {
                requiresAuth: false,
            },
            component: () =>
                import ('./components/Common/404'),
        },

    ],

})
router.beforeEach((to, from, next) => {
    if (
        to.matched.some((route) => route.meta.requireAuth == true) &&
        store.state.isLoggedIn == false
    ) {
        console.log(from);
        next({ name: "login", params: { lastPage: from } });
        return;
    }
    if (store.state.isLoggedIn == true) {
        axios({
                method: "POST",
                url: "/validate/token",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
            .then((response) => {
                if (!response.data.status) {
                    store.commit("sessionOut", true);
                    return;
                }
            })
            .catch((err) => {
                var msg = err;
                console.log(msg);
            });
    }
    if (
        (to.name == "login") &&
        store.state.isLoggedIn == true
    ) {
        next({ name: "dashboard" });
        return;
    }
    next();
});
export default router